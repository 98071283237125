

























































































































































import router from "@/router";
import { Component, Vue, Watch } from "vue-property-decorator";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { BButton, BFormInput, BFormGroup, BForm, BSpinner, BPagination, BFormFile } from "bootstrap-vue";
import { RapportActiviteTemplate, RapportActiviteConfig, CreateRapportActiviteTemplateModel } from "@/api/models/rapportActivites/rapportActiviteTemplate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";

@Component({
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BSpinner,
    BPagination,
    DeleteIcon,
    EditIcon,
    SearchableVueSelect,
    BFormFile
  },
})
export default class listTemplates extends Vue {
  required = required;
  min = min;
  max = max;
  droitsEnum = DroitsEnum;

  mainLoading: boolean = false;
  currentPage = 1;
  pageSize = 10;

  listChartes: any[] = [];

  selected: any = null;

  listTemplates: PaginatedList<RapportActiviteTemplate> =
    new PaginatedList<RapportActiviteTemplate>();

  newTemplate: CreateRapportActiviteTemplateModel = {}

  async created() {
    await this.loadTemplates()
    await this.loadCharteGraphique()
  }

  @Watch('currentPage')
  async currentPageChange(){
    await this.loadTemplates()
    await this.loadCharteGraphique()
  }

  async loadTemplates() {
    this.mainLoading = true;
    await this.$http.ressifnet.rapportActiviteTemplates
      .paginatedList(
        this.currentPage,
        this.pageSize
      )
      .then((response: PaginatedList<RapportActiviteTemplate>) => {
        this.listTemplates = response;
        this.mainLoading = false;
      });
  }

  async loadCharteGraphique() {
    await this.$http.ressifnet.charteGraphique
      .paginatedList()
      .then((response: any) => {
        console.log(response)
        this.listChartes = response["items"]
      },
      (error: any) => {
        console.log(error)
      });
  }


  goDetails(rapportActiviteTemplateId: any): any {
    router.push({
      name: "admin-rapport-activite-details",
      params: { rapportActiviteTemplateId: rapportActiviteTemplateId },
    });
  }

  async confirmCreate(event: any) {
    event.preventDefault();

    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess: boolean) => {
        if (isSuccess) {
          await this.$http.ressifnet.rapportActiviteTemplates.post(this.newTemplate).then(
            async (response: any) => {
              this.$bvModal.hide("modal-post-template");
              successAlert.fire({
                title: "Création d'un template de rapport d'activité",
                text: "Création effectuée avec succès",
              });

              router.push({
                name: "admin-rapport-activite-details",
                params: { rapportActiviteTemplateId: response },
              });
            },
            (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                this.$bvModal.hide("modal-post-template");
                errorAlert.fire({
                  text: error.message,
                });
              }
            }
          );
        }
      });
  }

  async deleteTemplate(templateId: string) {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer le template ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if(value){
          await this.confirmDelete(templateId)
        } 
        else {
          return
        }
      })
  }

  async confirmDelete(templateId: string) {
    await this.$http.ressifnet.rapportActiviteTemplates.delete(templateId)
      .then(async(response: string) => {
        successAlert.fire({
          title: "Suppression d\'un template de rapport d'activité",
          text: "Suppression effectuée avec succès"
        })

        await this.loadTemplates()
      }, (error: any) => {
        errorAlert.fire({
          text: error.message,
        })
      })
  }
}
